<template>
<div>
    <div v-if="loads==0" class="mask" @touchmove.prevent>&nbsp;</div>
    <b-container fluid="xs">
      <div class="boxw devbox1">
        <div class="cheader li1 pl-1 pr-1" :class="loading?'hasnet':'nonet'">
          <div>
            <span v-if="!mynickname">{{'ChargerID:'|trans}}</span>&nbsp;#{{chargerid}}
          </div>
          <div v-if="contentId==0">
            <span class="text-right" v-if="mytoken"><b-button class="btn btn-sm mybtn" variant="outline-success" @click="inputpays">{{'topup'|trans}}</b-button>&nbsp;{{mybalance}}&nbsp;</span>
          </div>
          <div v-else>
            <span class="text-right" v-if="mytoken"><b-button class="btn btn-sm mybtn" variant="outline-warning" @click="dologout">{{'logout'|trans}}</b-button>&nbsp;</span>
          </div>
        </div>
        <template v-if="contentId==0">
          <div class="xn-squrebox">
            <div class="xn-am1 am1b">
              <div class="xn-am2 am1b">
                <div class="xn-ambm" :class="'bg'+sw[portid]">
                  <div class="xn-fee">{{'lowprize'|trans}} {{lowprize}} {{'powerunit'|trans}}</div>
                  <div class="xn-amf0 pos-amf0">
                    <div class="xn-amf1 pos-amf1">
                      {{"acTempture"|trans}}
                      <span class="xn-amf2 pos-amf2">{{portid==-1?'___':tp[portid]}}°C</span>
                    </div>
                    <div class="xn-amf1 pos-amf1">
                      {{"acState"|trans}}
                      <span class="xn-amf2 pos-amf2">{{portid==-1?'___':st[sw[portid]]}}</span>
                    </div>
                    <div class="xn-amf1 pos-amf1">
                      {{"acPower"|trans}}
                      <span class="xn-amf2 pos-amf2">{{portid==-1?'___':pw[portid]}}</span>
                    </div>
                    <div class="xn-amf1 pos-amf1">
                      {{"acCurrency"|trans}}
                      <span class="xn-amf2 pos-amf2">{{portid==-1?'___':pi[portid]}}</span>
                    </div>
                    <div class="xn-errmsg err-fs">
                       {{errormsg}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="gunpos guntop">
                  <div class="chead2 li1">
                    <div class="gs pbogun" :class="portid==0?'p0 leftgunselected':'p1'" id="0" @click="selectme($event)">{{'leftgun'|trans}}</div>
                    <div class="gs pbogun" :class="portid==1?'p0 rightgunselected':'p1'" id="1" @click="selectme($event)">{{'rightgun'|trans}}</div>
                  </div>
              </div>
            </div>
          </div>
          <b-button block class="mainbtn" variant="info" :to="{path:'/login',query:{backid:chargerid}}" v-if="!mytoken">{{'btn_login'|trans}}</b-button>
          <b-button block class="mainbtn" variant="primary" @click="inputpays" v-if="mytoken && mybalnum<10">{{'btn_prepay'|trans}}</b-button>
          <b-button block class="mainbtn" variant="success" @click="dochargeac" v-if="mytoken && mybalnum>=10" :disabled="noclick">{{btntext}}</b-button>
        </template>
        <template v-if="contentId==1">
          <div class="weui-panel xnpanel mt-3 pt-4 pb-3">
            <div block class="text-right" style="margin-top:-10px">
            <b-icon block icon="x-circle" font-scale="1.5" variant="danger" @click="cancelpay"></b-icon>
            </div>
            <b-form-group style="margin-top:-20px;">
              <p class="mt-2">{{'payfullname'|trans}}</p>
              <b-form-input size="lg" type="span" v-model="payfullname" :placeholder="$t('message.hpayfullname')"
                required maxlength="32"></b-form-input>
            </b-form-group>
            <b-form-group>
              <p class="mt-2">{{'paymoneys'|trans}}</p>
              <b-form-input size="lg" type="span" v-model="payamount" required maxlength="8"></b-form-input>
            </b-form-group>
            <paystack class="pay" :amount="payamount*100" :email="payemail" :paystackkey="paystackpubkey"
              :reference="reference" :callback="paycallback" :close="payclose" :embed="false" :channels="channels" currency="GHS">
              {{'btn_prepay'|trans}}
            </paystack>
            <div class="mt-4 mb-3">
              <img src="images/paystack-gh.png" class="w-100"/>
            </div>
          </div>
        </template>
      </div>
    </b-container>
</div>
</template>
<script>
  import paystack from 'vue-paystack';
  import { nanoid } from 'nanoid';
  import { paystackpublickey } from '@/config'
  import { prepaylimit } from '@/config'
  import { defaultpaystackid } from '@/config'
  export default {
    name: 'chargerev',
    components: {
      paystack
    },
    mounted() {
      this.authen();
      this.fetchData();
    },
    computed: {
      reference() {
        return nanoid();
      },
      channels() {
        return ["card", "bank", "ussd", "mobile_money"];
      }
    },
    data() {
      return {
        paystackpubkey: paystackpublickey,
        payfullname: localStorage.pfname,
        payemail: defaultpaystackid,
        payamount: localStorage.preprepay?localStorage.preprepay:prepaylimit,
        loads: 0,
        keeploading: true,
        mac: undefined,
        chargerid: this.$route.params.id,
        portid: -1,
        noclick: true,
        norefresh: false,
        step: 0,
        btntext: 'SelctGunFirst',
        myid: -1,
        mytoken: '',
        mynickname: '',
        mybalance: '0.00',
        mybalnum: 0,
        contentId: 0,
        charging: 0,
        loading: false,
        stp: 0,
        lowprize: '0.00',
        errormsg: 'plz select working gun.',
        st: ['Free', 'Ready', 'Charging', 'Disabled', 'StopDN', 'Offline', 'noID'],
        ua: ['',''],
        sw: [4, 5],
        pw: [0, 0],
        pi: [0, 0],
        tp: [0, 0],
        cp: [120, 120],
        imax: [32, 0]
      }
    },
    methods: {
      async paycallback(response) {
        this.contentId = 0;
        let lotoken = localStorage.getItem('token');
        let qryparams = 'token=' + lotoken + '&ref=' + response.reference;
        await this.axios.post('/paystackcb?tm=' + new Date().getTime(), qryparams);
        this.loads = 1;
        if (!this.keeploading) {
          this.fetchData();
        }
        localStorage.setItem('preprepay', this.payamount);
        localStorage.setItem('pemail', this.payemail);
        localStorage.setItem('pfname', this.payfullname);
        this.authen();
      },
      payclose() {
        this.contentId = 0;
      },
      async dologout() {
        localStorage.removeItem('nickname');
        localStorage.removeItem('token');
        window.location.reload();
      },
      async fetchData() {
        let lotoken = localStorage.getItem('token');
        let qryparams = 'token=' + lotoken + '&loads=' + this.loads;
        if (this.mac) {
          qryparams = qryparams + '&mac=' + this.mac;
        } else {
          qryparams = qryparams + '&id=' + this.chargerid;
        }
        this.loading = true;
        let result = await this.axios.post('/getoneac?tm=' + new Date().getTime(), qryparams);
        this.loading = false;
        if (result && result.status >= 200) {
          let leftin = 0;
          let rightin = 0;
          if ( this.imax[0]>0 && this.imax[1]>0 ) {
            let delta0 = this.cp[0]-result.data.cp[0];
            let delta1 = this.cp[1]-result.data.cp[1];
            if (delta0>21 && this.cp[0]>100) {
              leftin = 1;
            }
            if (delta1>21 && this.cp[1]>100) {
              rightin = 1;
            }
          }
          this.loads++;
          if ( this.norefresh == false ) {
            Object.keys(result.data).forEach((key) => {
              this[key] = result.data[key];
            });
            if ( leftin && this.portid==1 ) this.portid = 0;
            if ( rightin && this.portid==0 ) this.portid = 1;
            if (this.portid>-1) {
              this.selectport(this.portid);
            }
          } else {
            if (this.sw[this.portid]!=result.data.sw[this.portid]) {
              this.norefresh = false;
            }
          }
          if (this.loads < 100) {
            this.keeploading = true;
            setTimeout(this.fetchData, 1000);
          } else {
            this.keeploading = false;
          }
        } else {
          console.error(result);
        }
      },
      inputpays() {
        this.contentId = 1;
      },
      cancelpay() {
        this.contentId = 0;
      },
      selectport(id) {
        if (this.sw[id] == 0) {
          if (id==0) {
            this.btntext = 'DoCharge(#Left)';
          } else {
            this.btntext = 'DoCharge(#Right)';
          }
          this.noclick = false;
          if (this.portid==id) {
            this.errormsg = '';
          } else {
            this.errormsg = 'You can start charge now.';
          }
        } else if (this.sw[id] == 1) {
          this.btntext = 'gunReady,ChargeNow';
          this.noclick = false;
          if (this.portid==id) {
            this.errormsg = '';
          } else {
            this.errormsg = 'You can start charge now.';
          }
        } else if (this.sw[id] == 2) {
          if (this.myid == id) {
            this.btntext = 'STOP NOW';
            this.noclick = false;
          } else {
            this.btntext = 'Charging';
            this.noclick = true;
          }
          this.errormsg = this.ua[id]+' is Charging';
        } else if (this.sw[id] == 3) {
          this.btntext = 'Gun Disabled';
          this.noclick = true;
          this.errormsg = '';
        } else if (this.sw[id] == 4) {
          this.btntext = 'STOPed';
          this.noclick = true;
          if (this.portid==id) {
            this.errormsg = '';
          } else {
            this.errormsg = 'Clockwise rotation the STOPKEY!';
          }
        } else if (this.sw[id] == 5) {
          this.btntext = 'Device Offline';
          this.noclick = true;
          if (this.portid==id) {
            this.errormsg = '';
          } else {
            this.errormsg = 'Check network connection.';
          }
        } else if (this.sw[id] == 6) {
          this.btntext = 'no CHARGERID';
          this.noclick = true;
          if (this.portid==id) {
            this.errormsg = '';
          } else {
            this.errormsg = 'Visit the corrent page!';
          }
        }
        this.portid = id;
      },
      selectme(e) {
        let id = parseInt(e.currentTarget.id);
        this.selectport(id);
        this.loads = 1;
        if (!this.keeploading) {
          this.fetchData();
        }
      },
      async authen() {
        let lotoken = localStorage.getItem('token');
        if (lotoken) {
          let loginparam = 'token=' + lotoken + '&tm=' + new Date().getTime();
          let loginresult = await this.axios.post('/chargeauth?tm=' + new Date().getTime(), loginparam);
          if (loginresult && loginresult.status == 200) {
            let nickname = localStorage.getItem('nickname');
            this.mytoken = lotoken;
            this.mynickname = nickname;
            this.mybalnum = loginresult.data.balnum;
            this.mybalance = loginresult.data.balance;
          } else {
            localStorage.removeItem('token');
            localStorage.removeItem('nickname');
          }
        }
      },
      async dochargeac() {
        this.noclick = true;
        this.norefresh = true;
        let lotoken = localStorage.getItem('token');
        if (this.sw[this.portid] < 2) {
          this.btntext = 'Starting';
          let doparams = 'token=' + lotoken + '&mac=' + this.mac + '&portid=' + this.portid;
          await this.axios.post('/dochargeac?tm=' + new Date().getTime(), doparams);
        } else if (this.myid==this.portid){
          this.btntext = 'Stoping';
          let doparams = 'cmd=1&token=' + lotoken + '&mac=' + this.mac + '&portid=' + this.portid;
          await this.axios.post('/userdocmd?tm=' + new Date().getTime(), doparams);
        }
        setTimeout(() => { this.noclick = false; this.norefresh = false; }, 10000);
        this.loads = 1;
        if (!this.keeploading) {
          this.fetchData();
        }
      },
    }
  }
</script>
<style>
  .gunpos {
    position: absolute;
    width:100%;
  }
  .guntop{
    top:320px;
  }
  .pbogun {
    z-index: 9999;
    border-radius: 10px;
    border: 2px dotted #aaa;
  }
  .gs {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 8px 20px 8px 20px;
  }
  .li1 {
    display: flex;
    justify-content: space-between;
  }
  .p0 {
    background: rgba(0, 204, 0, 0.4);
  }
  .p1 {
    background: rgba(0, 0, 0, 0.1);
  }
  @media (prefers-color-scheme: dark) {
    .am1b {
      background: rgba(32, 32, 32, 0.2);
    }
  }
  @media (prefers-color-scheme: light) {
    .am1b {
      background: rgba(221, 221, 221, 0.2);
    }
  }
  .xn-amf0 {
    width: 100%;
    display: flex;
    flex-flow: wrap;
  }
  .pos-amf0 {
    margin-top: 0rem;
  }
  .xn-amf1 {
    color: #999;
    text-align: center;
    width: 50%;
  }
  .pos-amf1 {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  .xn-amf2 {
    text-align: center;
    color: #009474;
    display: block;
  }
  .pos-amf2 {
    font-size: 2rem;
    margin-top: 0.5rem;
  }
  .xn-errmsg {
    color: rgba(255, 255, 255);
    text-align: center;
    width: 100%;
  }
  .err-fs {
    font-size: 1.6rem;
  }
  .bg-1 {
    background: rgba(128, 0, 0, 0.1);
  }
  .bg0 {
    background: rgba(0, 255, 0, 0.5);
  }
  .bg1 {
    background: rgba(255, 255, 0, 0.2);
  }
  .bg2 {
    background: rgba(255, 255, 0, 0.5);
  }
  .bg3 {
    background: rgba(255, 255, 0, 0.9);
  }
  .bg4 {
    background: rgba(255, 0, 0, 0.2);
  }
  .bg5 {
    background: rgba(128, 0, 0, 0.1);
  }
  .bg6 {
    background: rgba(153, 153, 153, 0.1);
  }
  .tinyst {
    font-size: 0.5rem;
    color: #bbb;
  }
  .xn-fee {
    font-size: 1.5rem;
    text-align: center;
  }
  .mainbtn {
    line-height: 4rem;
    font-size: 2rem;
  }
  @media only screen and (orientation: portrait) {
    .xn-am1 {
      width: 100vw;
      height: 100vw;
      border-radius: 100%;
      position: relative;
      top: 0px;
    }
    .xn-am2 {
      width: 98vw;
      height: 98vw;
      border-radius: 100%;
      position: relative;
      left: 1vw;
      top: 1vw;
      box-shadow: 10px 10px 10px rgba(51, 51, 51, .1);
    }
    .xn-squrebox {
      z-index: 999;
      height: 90vw;
      overflow: hidden;
    }
    .xn-ambm {
      width: 96vw;
      height: 96vw;
      border-radius: 100%;
      position: relative;
      left: 1vw;
      top: 1vw;
      box-shadow: 20px 20px 20px rgba(51, 51, 51, .6);
    }
    .guntop{
      top:70vw;
    }
    .gs {
      font-size: 4vw;
      padding: 3vw 6vw 3vw 6vw;
    }
    .xn-fee {
      font-size: 4vw;
      text-align: center;
    }
    .err-fs {
      font-size: 6vw;
    }
    .pos-amf0 {
      margin-top: 0px;
      padding-top: 0px;
    }
    .mainbtn {
      line-height: 20vw;
      font-size: 6vw;
    }
    @media (min-width: 760px) {
      .pos-amf0 {
        margin-top:2vw;
        padding-top:3vw;
      }
      .pos-amf1 {
        font-size: 2rem;
        margin-top: 2rem;
      }
      .pos-amf2 {
        font-size: 3rem;
        margin-top: 1rem;
      }
    }
    @media (min-width: 992px) {
      .pos-amf0 {
        margin-top: 2vw;
        padding-top: 3vw;
      }
      .pos-amf1 {
        font-size: 3rem;
        margin-top: 2rem;
      }
      .pos-amf2 {
        font-size: 4rem;
        margin-top: 1rem;
      }
    }
  }

  @media only screen and (orientation: landscape) {
    .boxw {
      width: 500px;
    }
    .xn-am1 {
      width: 498px;
      height: 498px;
      border-radius: 100%;
      position: relative;
      top: 0px;
    }
    .xn-am2 {
      width: 460px;
      height: 460px;
      border-radius: 100%;
      position: relative;
      left: 20px;
      top: 20px;
      box-shadow: 10px 10px 10px rgba(51, 51, 51, .1);
    }
    .xn-squrebox {
      /* border: red dotted 1px; */
      z-index: 999;
      height: 400px;
      overflow: hidden;
    }
    .xn-ambm {
      width: 420px;
      height: 420px;
      border-radius: 100%;
      position: relative;
      left: 20px;
      top: 20px;
      box-shadow: 20px 20px 20px rgba(51, 51, 51, .6);
    }
  }
  .devbox1 {
    margin: 0 auto;
  }
  .pay {
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 1.6rem;
    color: blue;
  }
  .hasnet {
    color: rgba(0, 255, 0, 0.6);
    text-shadow: 4px 4px 8px rgba(51, 51, 51, .2);
  }
  .nonet {
    color: rgba(102, 102, 102, 0.5);
    text-shadow: 4px 4px 8px rgba(51, 51, 51, .2);
  }
  .mask {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .leftgunselected {
    padding-right: 5rem;
  }
  .rightgunselected {
    padding-left: 5rem;
  }
</style>
